body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  background: black;
}
#root {
  height: 100%;
}

@keyframes mouseMove {
  0% {
    opacity: 1;
    transform: translateY(10px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}

@-webkit-keyframes mouseMove /*Safari and Chrome*/ {
  0% {
    opacity: 1;
    transform: translateY(10px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}

#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
  overflow: auto;
}
