.article-card {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 5px 10px 5px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  &.column {
    flex-direction: column;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .card-left {
    display: flex;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img,
    video {
      width: 100px;
      height: 80px;
      object-fit: cover;
      // background: white;
    }
    &.multi {
      align-items: center;
      justify-content: space-between;
      margin: 0 0 5px 0;
      img {
        padding-right: 3px;
        height: 80px;
        width: 33%;
      }
      img:nth-child(1) {
        padding-left: 0;
      }
    }
  }
  .card-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    .cart-title {
      font-size: 14px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .cart-sketch {
      font-weight: normal;
      font-size: 13px;
      color: rgb(102, 102, 102);
      // height: 56px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .cart-label-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: normal;
      color: grey;
      margin-bottom: 0;
      .cart-label-box-l {
        background: #1990ff;
        color: white;
        padding: 1px 4px;
        border-radius: 3px;
      }
    }
  }
}
