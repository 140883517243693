.label-secpage-wrap {
  width: 100%;
  height: 100%;
  .sec-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    background: #fafafa;
    font-weight: normal;
    // border: 1px solid #d5cfe3;
    border-radius: 5px;

    .sec-header-title {
      font-weight: bold;
    }
  }
  .secpage-content {
    width: 100%;
    height: calc(100% - 65px);
    overflow: auto;
    .collapse-box {
      border: 0px !important;
      background-color: #fafafa !important;
    }
    .first-line {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0px;
      .fl-right {
        opacity: 0.3;
      }
      &:hover .fl-right {
        opacity: 1;
      }
    }
    .second-label-box {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
      &:last-child {
        border-bottom: 0px solid #ebebeb;
        margin-bottom: 0px;
      }
      .seclabel-name {
        font-size: 14px;
        color: #020202;
        width: 120px;
        display: flex;
        background: #ededed;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 0px 15px;
      }
      .seclabel-description {
        margin-left: 10px;
        font-size: 12px;
        color: #666;
        width: calc(100% - 130px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .sd-left {
          width: calc(100% - 90px);
          display: flex;
          align-items: center;
        }
        .sd-right {
          margin-left: 10px;
          width: 90px;
          font-size: 12px;
          text-align: right;
          display: flex;
          opacity: 0.3;
          justify-content: center;
          align-items: center;
        }
        &:hover .sd-right {
          opacity: 1;
          display: flex;
        }
      }
    }
  }
}
