.article-secpage-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .sec-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    background: #fafafa;
    // border: 1px solid #d5cfe3;
    font-weight: normal;
    border-radius: 5px;
    .ant-upload-list {
      display: none;
    }
    .sec-header-title {
      font-weight: bold;
    }
  }
  .secpage-content {
    width: 100%;
    overflow: auto;
    display: flex;
    flex: 1;
    flex-direction: row;
    .content-list {
      display: flex;
      width: 320px;
      background: #fafafa;
      border-radius: 15px;
      padding: 1vw;
      margin-right: 1vw;
      flex-direction: column;
      .title {
        font-weight: bold;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 5px;
        .edit-tool {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
      .list-wrap {
        flex: 1;
        overflow: auto;
      }
    }
    .content-info {
      display: flex;
      width: 300px;
      background: #fafafa;
      border-radius: 15px;
      padding: 1vw;
      flex-direction: column;
      flex: 1;
      .title {
        font-weight: bold;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .label-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 0;
        width: 100%;
        opacity: 0.75;
        // letter-spacing: 1px;
        .label-tag {
          background: #e3e3e3;
          border-radius: 3px;
          padding: 1px 3px;
          margin-right: 5px;
        }
        .label-content {
          margin-left: 5px;
          color: rgb(112, 112, 112);
        }
      }
      .info-wrap {
        flex: 1;
        overflow: auto;
        padding: 1vh 5vw 3vh 5vw;
        .info-sketch {
          display: flex;
          align-items: center;
          // justify-content: center;
          font-size: 12px;
          font-weight: normal;
          color: #666;
          background: #eee;
          padding: 15px;
          border-radius: 5px;
          margin-bottom: 40px;
        }
        .info-section-text {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .text-box {
            text-align: left;
            // background: #f7f7f7;
            width: 100%;
            text-indent: 28px;
            padding: 10px;
            border-radius: 15px;
            white-space: pre-line;
          }
        }
        .info-section-img {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 100%;
          // max-height: 300px;
          object-fit: contain;
        }
      }
    }
    .pagination-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
.article-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
}
.article-loading {
  position: absolute;
  z-index: 111;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.article-pdf-file-box {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 5px;
  border-radius: 3px;
  cursor: pointer;
}
.article-pdf-file-box:hover {
  background: #f1f1f1;
}
