.question-secpage-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .sec-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    background: #fafafa;
    border: 1px solid #d5cfe3;
    font-weight: normal;
    border-radius: 25px;
    .ant-upload-list {
      display: none;
    }
    .sec-header-title {
      font-weight: bold;
    }
  }
  .secpage-content {
    width: 100%;
    flex: 1;
    overflow: auto;

    .first-line {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0px;
      .fl-right {
        opacity: 0.3;
      }
      &:hover .fl-right {
        opacity: 1;
      }
    }
    .second-label-box {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
    }
  }
}
.question-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
}
.question-loading {
  position: absolute;
  z-index: 111;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}
