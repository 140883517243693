.rich-text-container {
  width: 100%;
  padding: 0 0 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  .rich-text-tool {
    background: #ececec;
    /* padding: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 10px;
  }
  .rich-text-box {
    padding: 10px;
    overflow: auto;
    width: 100%;
    height: 600px;
    white-space: break-spaces;
    // font-size: 12px;
    &[contenteditable] {
      outline: 0px solid transparent;
    }
    img {
      max-width: 500px;
      // max-height: 100px;
      background-color: #dfdfdf;
      display: block;
    }
  }
}
.article-content-image {
  width: 100%;
  margin: 5px 0;
  padding: 0 3%;
  box-sizing: border-box;
}
.article-content-video {
  max-width: 200px;
  max-height: 100px;
  background-color: black;
  display: block;
}
.intLink {
  cursor: pointer;
  width: 20px;
  margin-right: 5px;
}

img.intLink {
  border: 0;
}
