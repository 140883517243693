.custom-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  background-color: #960000;
  .logo {
    color: #eee;
    font-size: 22px;
    // text-shadow: -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff,
    //   1px 1px 0 #fff, 1px 1px 0 #444,2px 1px 0 #444, 2px 2px 0 #444,
    //   3px 2px 0 #444, 3px 3px 0 #444;
    // letter-spacing: 1px;
    transform: scale(1.1, 1);
    transform-origin: left;
  }
  .user-box {
    color: white;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    .account-text {
      font-weight: bolder;
      position: relative;
      color: rgb(179, 192, 203);
      font-size: 16px;

      // 为文本元素提供渐变背景。
      background-image: -webkit-linear-gradient(bottom, #50cab0, yellow);

      // 使用透明颜色填充文本。
      background-clip: text;

      // 用文本剪辑背景，用渐变背景作为颜色填充文本。
      -webkit-text-fill-color: transparent;
    }
    .user-avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 5px;
      position: relative;
      top: 7px;
      object-fit: cover;
    }
  }
}
