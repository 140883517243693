.dashboard {
  .header-title {
    font-size: 16px;
    font-weight: bold;
  }
  .content-box {
    display: flex;
    .section {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      color: white;
      margin: 15px;
      padding: 15px;
      border-radius: 15px;
      height: 12vh;
      width: 10vw;
      .box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        color: white;
        margin-bottom: 5px;
        .title {
          font-size: 18px;
          font-weight: bold;
          text-shadow: 1px 1px 0 #999;
        }
      }
    }
  }
}
