/* 万视，用心打造数字化产品和品牌 */
.login-wrap {
  width: 100%;
  height: 100%;
}
.icp-module {
  display: flex;
  width: 100%;
  color: white;
  position: absolute;
  bottom: 20px;
  /* left: 0; */
  /* right: 0; */
  /* margin: auto; */
  align-items: center;
  justify-content: center;
}
